//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LightBlock',
  data() {
    return {};
  },
  props: ['content'],
  components: {},
  methods: {
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
  },
  watch: {},
  computed: {
    parsedContent() {
      let ret = this.content;
      const urlReg =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=!]*)/;

      this.content.forEach((single, key) => {
        if (single.type == 'PARAGRAPH') {
          single.data.forEach((block, blockKey) => {
            const linkMatch = block.match(urlReg);
            if (linkMatch) {
              ret.push({ type: 'LINK', data: { url: linkMatch[0] } });
              ret[key].data[blockKey] = block.replace(linkMatch[0], ''); // replace original link in text with emptiness
            }
          });
        }
      });
      return ret;
    },
  },
  mounted() {},
};
